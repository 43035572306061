export function geJwtAuthenticationToken() {
  const userId = localStorage.getItem('userId');
  const authenticationMetadata = localStorage.getItem(userId);

  const {
    authentication: { accessToken },
  } = JSON.parse(authenticationMetadata);

  if (!accessToken) return null;

  return accessToken;
}
