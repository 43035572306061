import axios from 'axios';
import { API_URL } from 'config';
import { geJwtAuthenticationToken } from 'utils/localStorage';

const boardApiHttpClient = axios.create({
  baseURL: API_URL,
});

boardApiHttpClient.interceptors.request.use(
  (config) => {
    const jwtToken = geJwtAuthenticationToken();
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default boardApiHttpClient;
