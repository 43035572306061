import axios from 'axios';
import { LICENSEE_INTERNAL_API } from 'config';
import { DOWNLOAD_PDF_STATUSES } from '../constants';
import boardApiHttpClient from 'utils/httpClients/boardApi';

export const fetchCourses = (params) => {
  return boardApiHttpClient.request({
    url: `/course-history`,
    method: 'GET',
    params,
  });
};

export const fetchSubjectAreas = (professionId, userId = 0) => {
  return axios.request({
    method: 'GET',
    url: `${LICENSEE_INTERNAL_API}/course-filters`,
    params: {
      isAvailableInCourseSearch: true,
      professionId,
      userId,
    },
  });
};

export const fetchCourseDetail = (params, rosterAttendeeId) => {
  return boardApiHttpClient.request({
    method: 'GET',
    url: `/course-history/${rosterAttendeeId}`,
    params,
  });
};

export const fetchReportedExemptions = (params) => {
  return boardApiHttpClient.request({
    method: 'GET',
    url: `/reported-exemptions`,
    params,
  });
};

export const fetchReportedExemptionsQuestions = (lpExemptionId, params) => {
  return boardApiHttpClient.request({
    method: 'GET',
    url: `/reported-exemptions/${lpExemptionId}/questions`,
    params,
  });
};

export const setRevokeExemption = (lpExemptionId, data) => {
  return boardApiHttpClient.request({
    method: 'POST',
    url: `/reported-exemptions/${lpExemptionId}/revoke`,
    data,
  });
};

export const requestDownloadPdf = ({ credentials }) => {
  return boardApiHttpClient.request({
    url: `/cch-report/download-pdf`,
    data: {
      subscriberCredentials: credentials,
    },
    method: 'POST',
  });
};

export const getRequestStatus = ({ credentials }) => {
  return boardApiHttpClient.request({
    url: `/cch-report/get-request-status`,
    params: {
      userId: credentials.userId,
      pkLicense: credentials.pkLicense,
    },
    method: 'GET',
  });
};

export const updatePdfStatus = ({ pdfDataObject }) => {
  pdfDataObject.statusRequest = DOWNLOAD_PDF_STATUSES.DOWNLOADED;
  return boardApiHttpClient.request({
    url: `/cch-report/update-status-pdf`,
    data: {
      subscriberCredentials: pdfDataObject,
    },
    method: 'POST',
  });
};

export const browserDownload = ({ urlDocument }) => {
  return axios.request({
    url: `${urlDocument}`,
    method: 'GET',
    responseType: 'blob',
  });
};
